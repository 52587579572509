<template>
  <div class="score">
    <watch-head :title="$t('社交聊天室')"
                :img="require('@/assets/bracelet_img/38.png')" />

    <div class="boxCard">
      <div class="main">
        <!-- <img @click="close" class="close" src="@/assets/bracelet_img/40.png" alt=""> -->
        <div class="roomTitle">{{ chatInfo.name }}</div>
        <div class="subTitle">- {{ chatInfo.count }} / {{ chatInfo.max_count }} {{$t('评分')}} -</div>
        <div class="socialRating">{{$t('社交评分')}} :</div>
        <div class="list">
          <div class="item" v-for="(item, index) in evaluateLis" :key="index">
            <div class="avatar">
              <img :src="item.avatar" alt="">
              <div class="text">{{ item.level_grade }}</div>
            </div>
            <div class="right">
              <div class="name">{{ item.nickname || $t('暂无昵称') }}</div>
              <div class="grade">{{$t('评分')}} :</div>
              <div class="star">
                <van-rate v-model="partyEvaluateFrom[index].grade"
                          :count="rateCount"
                          :readonly="readonly"
                          color="#a7f264"
                          void-color="#2a2a2a" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <van-button :loading="submitLoading" :disabled="readonly" class="btn" @click="submitScore">{{$t('提交')}}</van-button>
    </div>

    <!-- <div class="btm">
      <div class="left">
        <div class="box">
          <img src="@/assets/bracelet_img/5.png"
               alt="">
        </div>
      </div>
      <div class="right">
        <div class="ask">请求</div>
        <div class="invite">邀请</div>
      </div>
    </div> -->
  </div>
</template>

<script>
import watchHead from '@/components/watchHead/index.vue'
import { mapState } from 'vuex'
import { ConfgScoreApi, ChatInfoApi, EvaluateListApi, partyEvaluateApi, mainConfig } from '@/api/api'
export default {
  components: {
    watchHead
  },
  data () {
    return {
      submitLoading: false,
      value: 0,
      chatInfo: {},
      confgScoreList: [],
      evaluateLis: [],
      partyEvaluateFrom: [],
      rateCount: 5,
      readonly: false, // 评分只读
    }
  },

  watch: {

  },
  computed: {
    ...mapState([
      'web3',
    ]),
  },
  created () {
    this.waitForGlobal()
  },
  methods: {
    //链接钱包
    waitForGlobal: function () {
      window.clearTimeout(this.timer);
      // 1. 刷新页面时是否已经连接钱包登录
      if (localStorage.getItem('Token') && this.web3 && localStorage.getItem('defaultAddress') && localStorage.getItem('userInfo')) {
        window.clearTimeout(this.timer);
        this.getChatInfo()
        this.getEvaluateList()
        this.getConfig();
      } else {
        // 如果检测到没有连接钱包登录
        this.timer = setTimeout(
          this.waitForGlobal
          , 100);
      }
    },
    getConfig(){
      mainConfig().then(res => {
        if(res.code == 0){
          let arrConfig = JSON.parse(res.data.score_star_rating)
          this.rateCount = arrConfig.length
        }
      })
    },
    /* 获取当前聊天室 */
    getChatInfo () {
      ChatInfoApi().then(res => {
        if (res.code == 0) {
          this.chatInfo = res.data
          this.chatInfo.count = this.chatInfo.members.length
        }
      })
    },
    /* 获取当前评分列表 */
    getEvaluateList () {
      EvaluateListApi().then(res => {
        if (res.code == 0) {
          this.evaluateLis = res.data.list
          this.readonly = res.data.is_finish == 1 ? true : false
          for (let i = 0; i < this.evaluateLis.length; i++) {
            this.partyEvaluateFrom.push({
              evaluate_id: this.evaluateLis[i].id,
              grade: this.evaluateLis[i].grade,
              // number: 0,
            })
          }
        }else if(res.code == 100){
          // 暂无房间信息
          this.$router.push('/roomHome')
        }
        this.$toast(res.msg);
      })
    },
    /* 提交评分 */
    submitScore () {
      this.submitLoading = true
      partyEvaluateApi({ data: this.partyEvaluateFrom }).then(res => {
        if (res.code == 0) {
          this.$router.push('/roomHome')
        }
        this.$toast(res.msg);
        this.submitLoading = false
      })
    },
  }
}
</script>

<style lang="less" scoped>
.score {
  width: 100%;
  min-height: 100vh;
  padding-top: 75px;
  padding-bottom: 67.5px;
  background: url(~@/assets/bracelet_img/8.png) no-repeat;
  background-size: 100% 100%;
  color: #ffffff;

  .boxCard {
    padding: 30px 33.75px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    .main {
      width: 100%;
      background-color: rgba(0, 0, 0, 0.4);
      border-radius: 7.5px;
      position: relative;
      padding: 11.25px 11.25px 18.75px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .close {
        width: 22.5px;
        height: 22.5px;
        position: absolute;
        right: 7.5px;
        top: 7.5px;
        z-index: 999;
      }
      .roomTitle {
        font-family: 'TsangerYuYangT-W05';
        font-size: 30px;
      }
      .subTitle {
        font-family: 'TsangerYuYangT-W05';
        font-size: 12px;
        margin-bottom: 18.75px;
      }
      .socialRating {
        width: 100%;
        font-family: '071-SSRuiFengTi';
        font-size: 30px;
        margin-bottom: 11.25px;
      }
      .list {
        padding: 0 7.5px;
        width: 100%;
        height: 42vh;
        overflow: auto;
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none; /* IE 10 */
        .item {
          min-height: 60px;
          background-image: linear-gradient(
              237deg,
              rgba(118, 233, 214, 0.8) 0%,
              rgba(159, 174, 230, 0.8) 100%
            ),
            linear-gradient(#ffffff, #ffffff);
          background-blend-mode: normal, normal;
          border-radius: 7.5px;
          display: flex;
          align-items: center;
          padding: 0 7.5px;
          margin-bottom: 3px;
          .avatar {
            width: 45px;
            min-width: 45px;
            height: 45px;
            min-height: 45px;
            border-radius: 50%;
            background-color: #2b2b2b;
            border: 2px solid #2b2b2b;
            position: relative;
            margin-right: 22.5px;
            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
            }
            .text {
              width: 22.5px;
              height: 22.5px;
              line-height: 22.5px;
              background-color: #2b2b2b;
              border-radius: 50%;
              position: absolute;
              right: -12px;
              bottom: -2px;
              text-align: center;
              font-family: 'AeroMaticsDisplayBoldItalic';
              font-size: 12px;
            }
          }
          .right {
            width: 100%;
            .name {
              font-family: 'AeroMaticsDisplayBoldItalic';
              font-size: 18.75px;
            }
            .grade {
              font-family: 'TsangerYuYangT-W05';
              font-size: 10px;
            }
            .star {
              text-align: end;
              padding-bottom: 3px;
            }
          }
        }
      }
      .list::-webkit-scrollbar {
        display: none;
      }
    }
    .btn {
      margin: 22.5px auto 0;
      background-color: #a7f264;
      border-radius: 15px 3px 15px 3px;
      border: 2px solid rgba(0, 0, 0, 0.8);
      padding: 3px 93.75px;
      font-family: 'REEJI-PinboGB-Flash';
      font-size: 22.5px;
      color: #2b2b2b;
    }
  }

  .btm {
    width: 100%;
    display: flex;
    align-items: center;
    position: fixed;
    bottom: 22.5px;
    .left {
      background-color: rgba(43, 43, 43, 0.5);
      border-radius: 0 3px 3px 0;
      padding: 3px 3px 3px 0;
      margin-right: 3px;
      .box {
        padding: 3px 10px 3px 7.5px;
        background-color: rgba(43, 43, 43, 0.9);
        border-radius: 0 3px 3px 0;
        border: solid 2px #7736fe;
        border-left: none;
        display: flex;
        img {
          width: 26px;
          height: 18.75px;
        }
      }
    }
    .right {
      background-color: rgba(43, 43, 43, 0.5);
      border-radius: 3px 26.25px 3px 3px;
      padding: 3.75px 20px 3.75px 7.5px;
      display: flex;
      align-items: center;
      font-family: 'REEJI-PinboGB-Flash';
      font-size: 18.75px;
      color: #2b2b2b;
      .ask {
        background-color: #5c65cb;
        box-shadow: 0px 0px 0px 0px rgba(19, 19, 19, 0.16);
        border-radius: 18.75px 3px 18.75px 3px;
        padding: 2.5px 48.75px;
        margin-right: 7.5px;
      }
      .invite {
        background-image: linear-gradient(#a7f264, #a7f264),
          linear-gradient(#5c65cb, #5c65cb);
        background-blend-mode: normal, normal;
        box-shadow: 0px 0px 0px 0px rgba(19, 19, 19, 0.16);
        border-radius: 18.75px 3px 18.75px 3px;
        padding: 2.5px 48.75px;
      }
    }
  }
}
</style>